:root {
  --mdc-theme-primary: #689f38 !important;
  --mdc-theme-secondary: 104, 159, 56 !important;
}

.fab {
  font-size: 24px !important;
}

.uk-navbar-toggle {
  padding-bottom: 10px !important;
}